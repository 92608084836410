/* @flow */

module.exports = {
  "LOCALE": {
    "ISO": {
      "639-1": "fi",
      "3166-1": "FI",
      "IETF": "fi-FI",
    },
    "CURRENCY": "EUR",
    "CURRENCY_S4HORT": "€",
    "POINTS": "pisteet",
    "TYPEKIT_ID": "thm8nca",
  },
  "COOKIE_CONSENT": {
    "NOTICE": "Parantaaksemme käyttökokemustasi digitaalisissa palveluissamme, käytämme evästeitä (cookies). Hyväksyt evästeiden käytön jatkaessasi nettisivujemme katselua.",
    "ALL": "Salli kaikki",
    "NECESSARY": "Salli tarvittavat",
    "SELECTED": "Salli valinta",
  },
  "COOKIE_OPTIONS": {
    "NECESSARY": "Välttämätön",
    "STATISTICAL": "Tilastollinen",
    "MARKETING": "Markkinointi",
  },
  "IE_NOTICE": "Emme tue käyttämääsi selainta. Sivuston toiminnat ovat rajoitetut tällä selaimella.",
  "COMMON": {
    "SAVE_AS_PDF": "PDF-muodossa",
    "PRINT": "Tulosta",
    "CLOSE": "Sulje",
    "CANCEL": "Peruuta",
    "APPROVE": "Hyväksyä",
    "REMOVE": "Poista",
    "SEND": "Lähettää",
    "READ_MORE": "Lue lisää",
    "FROM": "From",
    "TO": "Vastaanottaja",
    "SEE_ALL": "Katso kaikki",
    "EDIT": "Muokata",
    "ADD": "Lisätä",
    "GO_BACK": "Mene takaisin",
    "REGISTER": "Rekisteröidy",
    "I_ACCEPT_THE": "Hyväksyn",
    "ORDER_ID": "Tilausnumero",
  },
  "LOGO": {
    "ALT": "Logo",
    "TITLE": "Siirry aloitussivulle",
  },
  "OR": "Tai",
  "HOMEVIEW": {
    "HERO_ALT": "Awardit",
    "POPULAR_BRANDS": "Suositut tavaramerkit",
    "POPULAR_CATEGORIES": "Suositut kategoriat",
    "POPULAR_PRODUCTS": "Suositut tuotteet",
    "SEE_MORE": "Katso lisää",
    "BECOME_CUSTOMER": "Tule asiakkaaksi",
    "MORE_NEWS": "Lisää uutisia",
  },
  "ERRORVIEW": {
    "TITLE": "On tapahtunut virhe. Ole hyvä ja yritä uudelleen.",
  },
  "TERMSVIEW": {
    "FALLBACK_TITLE": "Tilausehdot",
    "ERROR_FETCHING": "Pyytämääsi sivua ei voitu löytää. Ole hyvä ja yritä myöhemmin uudelleen.",
    "MEMBERTAG_TITLE": "Tee valintasi",
    "MEMBERTAG_EMPLOYEE_OPTION": "Kyllä, haluan yritykseni myyjien osallistuvan Release Points -järjestelmään ja suostuvan saamaan pisteitä sopimuksista ja toiminnoista, jotka talletetaan suoraan heidän tililleen.",
    "MEMBERTAG_MANAGER_OPTION": "Ei, en halua myyjieni osallistuvan aktiivisesti Release Points -ohjelmaan. Pisteet talletetaan hallinnoimalleni yritystilille.",
    "ERROR_POST_MEMBERTAG": "Valintaasi ei voitu lähettää tällä hetkellä",
  },
  "ABOUTVIEW": {
    "TITLE": "Meistä",
  },
  "LOGIN": {
    "TITLE": "Kirjaudu sisään",
    "USERNAME": "Käyttäjätunnus",
    "USERNAME_EMAIL": "Käyttäjätunnus tai sähköpostiosoite",
    "ORGNR": "Organisaation numero",
    "PASSWORD": "Salasana",
    "SUBMIT": "Kirjaudu sisään",
    "FORGOT_PASSWORD": "Oletko unohtanut salasanasi?",
    "RESET_PASSWORD": "Tyhjennä",
    "CREATE_ACCOUNT_HEADING": "Eikö sinulla ole tiliä?",
    "CREATE_ACCOUNT": "Luo uusi tili",
  },
  "RECORDS": {
    "ACCOUNTING_RECORDS": "Kirjanpitoasiakirjat",
    "TEXT": "Täältä näet edellisten kuukausien kirjanpitoasiakirjat. Kirjanpitotiedot ovat saatavilla vasta, kun edellinen kuukausi on kulunut.</br> Jos haluat kirjanpitoasiakirjoja vuodelta 2021 tai sitä vanhemmalta - ota yhteyttä <a href='%link' rel='noopener noreferrer' target='_blank'>asiakaspalveluumme.</a>",
    "LINK_TEXT": "Jos sinulla on kysyttävää - ota yhteyttä <a href='%link' rel='noopener noreferrer' target='_blank'>asiakaspalveluumme.</a>",
    "EMPTY": "Kirjanpitotietoja ei ole vielä saatavilla.",
    "BUTTON": "Näytä kirjanpitoasiakirjat",
  },
  "MONTHS": {
    "1": "tammikuu",
    "2": "helmikuu",
    "3": "maaliskuuta",
    "4": "huhtikuu",
    "5": "saattaa",
    "6": "kesäkuuta",
    "7": "heinäkuu",
    "8": "elokuu",
    "9": "syyskuu",
    "10": "lokakuu",
    "11": "marraskuu",
    "12": "joulukuu",
  },
  "RESET_PASSWORD": {
    "EMAIL": "S-postiosoitteesi",
    "SUBMIT": "Uusi salasana",
  },
  "NEW_PASSWORD": {
    "TITLE": "Kirjoita uusi salasana",
    "PASSWORD": "Uusi salasana",
    "PASSWORD_REPEAT": "Toista uusi salasana",
    "SUBMIT": "Säästä",
  },
  "REGISTER": {
    "FIRSTNAME": "Etunimi",
    "LASTNAME": "Sukunimi",
    "EMAIL": "Sähköposti",
    "PHONE": "Puhelin",
    "COMPANY": "Yritys",
    "ORG_NUMBER": "Yrityksen tunnus",
    "TOTAL_MEMBERS": "Työntekijöiden määrä",
    "STREET_ADDRESS": "Katuosoite",
    "ZIPCODE": "Postinumero",
    "CITY": "Kaupunki",
    "OTHER": "Muu",
    "PNR": "Henkilötunnus",
    "GENDER": "Sukupuoli",
    "USER_ID": "Käyttäjätunnus",
    "SHOP": "Kauppa",
    "DISTRICT": "Piiri",
    "COUNTRY": "Maa",
    "DATE_OF_BIRTH": "Syntymäaika",
    "PASSWORD": "Salasana",
    "REPEAT_PASSWORD": "Toista salasana",
    "TERMS": "Hyväksyn <a href='%link'>ehdot</a>",
    "EXISITING_ACCOUNT": "Onko sinulla jo tili?",
  },
  "AFFILIATE": {
    "CTA": {
      "ACTIVATE": "Aktivoi",
      "ACTIVATED": "Aktivoitu",
      "PROCESSING": "Työstää",
      "INACTIVATED": "Ei aktivoitu",
    },
  },
  "EARN": {
    "SEARCH": "Etsi",
    "CLEAR_SEARCH": "Valmis",
    "SHOW_LESS": "Näytä vähemmän",
    "SHOW_MORE": "Näytä enemmän",
    "START": "Alkaa",
  },
  "BRAND": {
    "SEARCH": "Etsi tuotemerkki",
    "CLEAR_SEARCH": "Tyhjennä haku",
    "TOTAL_COUNT": "Näyttää %total tuotetta",
    "EMPTY": "Tuotteita ei näy",
  },
  "ACCOUNT": {
    "LOGIN": "Kirjaudu sisään",
    "LOGOUT": "Kirjaudu ulos",
    "MY_ACCOUNT": "Minun tilini",
    "USER": "Käyttäjätunnus",
    "MEMBERSHIP": "Jäsenyys",
    "MEMBER_NUMBER": "Jäsennumero",
    "SHIPPING_ADDRESS": "Toimitusosoite",
    "ELAPSED_POINTS": "Vanhentuneet %pointsLabel",
    "ELAPSING_POINTS_INFO": "Sinulla on %points %pointsLabel, jotka vanhentuvat %date (%dateRelative)",
    "EDIT_INFO": "Muuta tietojasi",
    "WISHLIST": "Toivelista",
    "EMAIL": "Sähköposti",
    "NO_PHONE_NUMBER": "Ei lisättyä puhelinnumeroa",
    "BALANCE": "Pistesaldo",
    "EXPIRES": "Vanhentuu",
    "DATE": "Päivämäärä",
    "SEE_ALL": "Katso kaikki",
    "EDIT": "Muuta",
    "OPEN": "Avaa",
    "YOUR_POINTS": "%pointsLabel-saldo",
    "NEWSLETTER": "Tilaa uutiskirje",
    "SAVING": "Säästää...",
    "SAVE_AND_CLOSE": "Säästä ja pistä kiinni",
    "CURRENT_PASSWORD": "Nykyinen salasana",
    "NEW_PASSWORD": "Uusi salasana",
    "CONFIRM_NEW_PASSWORD": "Vahvista uusi salasana",
    "GENDER": {
      "MALE": "Uros",
      "FEMALE": "Nainen",
      "UNSPECIFIED": "Määrittelemätön",
    },
    "MEMBER_GROUP": {
      "USERS": "Käyttäjät",
      "OWNER": "Omistaja",
      "CAN_LOGIN": "Voi kirjautua sisään",
      "DESCRIPTION": "Täällä voit lisätä, hallita ja poistaa käyttäjiä.",
      "EDIT_MEMBER": "Muokkaa jäsentä",
      "ADD_MEMBER": "Lisää jäsen",
      "SAVE_CHANGES": "Tallenna muutokset",
      "REMOVE_MEMBER": "Poista jäsen?",
      "REMOVE_MEMBER_CONFIRM": "Haluatko varmasti poistaa jäsenen %member?",
      "FULL_NAME": "Koko nimi",
      "MEMBER_ID": "Jäsen-ID",
      "SIGN_INS": "Kirjautumiset",
      "POINTS": "%pointsLabel",
      "NO_MEMBERS": "Ryhmässä ei ole jäseniä",
      "TRANSFER_POINTS": {
        "FORMHEADER": "%pointsLabel myöntäminen",
        "COMMENT": "Kommentti",
        "POINTS_AVAILABLE": "%pointsLabel saatavilla",
        "POINTS_HANDED_OUT": "%pointsLabel myönnetty",
        "POINTS_LEFT": "%pointsLabel vasemmalle",
        "CHOOSE_ALL": "Valitse kaikki",
        "DEFAULT_MESSAGE": "Pisteiden siirto",
        "HISTORY": {
          "HEADER": "Edelliset osingot",
          "DATE": "Päivämäärä",
          "RECIPIENTS": "Vastaanottaja",
          "DISTRIBUTED": "Hajautettu",
        },
      },
      "TRANSACTIONS": {
        "DISPLAY": "Näytä tapahtumat",
        "HEADER": "Tapahtumat ja saldo",
        "REGISTRATION_DATE": "Rekisteröintipäivä:",
        "ACTIVATION_DATE": "Aktivointipäivämäärä:",
        "CURRENT_BALANCE": "Nykyinen tasapaino:",
        "EXPIRING_POINTS": "Ensi kuussa vanhentuvien pisteiden määrä (%date):",
        "ACCUMULATED_BALANCE": "Kertynyt saldo:",
        "NO_TRANSACTIONS": "Tälle jäsenelle ei ole saatavilla tapahtumia.",
      },
    },
  },
  "RECRUIT_A_FRIEND": {
    "LABEL_NAME": "Nimi",
    "LABEL_EMAIL": "Sähköposti",
    "LABEL_MESSAGE": "Viesti",
    "LABEL_PHONE": "Puhelin",
  },
  "TINK": {
    "ACTIVE": "Voimassa oleva",
    "INACTIVE": "Ei voimassa oleva",
    "REMOVE_PROVIDER": "Poista",
    "RENEW_PROVIDER": "Päivitä",
    "REMAINING_DAYS": {
      "SINGULAR": "1 päivä jäljellä",
      "PLURAL": "%days päivää jäljellä",
    },
    "REMOVE_CONFIRM": "Oletko varma, että haluat katkaista yhteyden osoitteeseen \"%name\"?",
    "CONFIRM": {
      "TITLE": "Katkaise yhteys",
      "OK": "Vahvista",
      "CANCEL": "Peruuta",
    },
    "MODAL": {
      "TITLE": "Uusi tilisi hyväksyntä",
      "SUBTITLE_PART1": "Saadaksesi %pointsLabel ostoksistasi, uusi tapahtumiesi käsittely",
      "SUBTITLE_PART2": "Uusi hyväksyntä",
      "YOUR_ACCOUNT": "tilisi kautta",
    },
  },
  "POINTS": {
    "HEADER": "Siirto-%pointsLabel",
    "MY_POINTS": "Minun %pointsLabel",
    "INTRO": "Voit siirtää pisteitä alla olevalla lomakkeella",
    "TRANSFERING": "Siirto...",
    "TRANSFER": "Siirto-%pointsLabel",
    "AMOUNT_OF_POINTS": "Montako %pointsLabel haluat siirtää?",
    "SELECT_ACCOUNT": "Valitse tili",
    "FROM_ACCOUNT": "Tililtä",
    "TO_ACCOUNT": "Tilille",
    "AMOUNT": "Saldo",
  },
  "QUIZ_ITEM": {
    "NUM_QUESTIONS": "%questions kysymys",
    "POINTS": "%points %pointsLabel",
    "STATUS": {
      "CLOSED": "Suljettu",
      "ANSWERED": "Vastattu",
      "UNANSWERED": "Ei vastattu",
    },
    "SHOW_ANSWER": "Näytä vastaus",
  },
  "QUIZ": {
    "HEADING": "Kysy %step prosentista %of",
    "INPUT_LABEL": "Anna vastauksesi",
    "LIST_ALTERNATIV_PLACEHOLDER": "Valitse asetukset",
    "IMAGE_INPUT_LABEL": "Lähetä kuva",
    "IMAGE_INPUT_BUTTON": "Valitse tiedosto",
    "NEXT": "Jatka",
    "SUBMIT": "Tallenna",
    "ALREADY_ANSWERED": "Olet jo vastannut tähän kyselyyn. Alta löydät vastauksesi.",
    "CLOSED": "Tämän kyselyn aika on umpeutunut. Et voi enää vastata siihen.",
    "EMPTY": "Ei ole mitään näytettävää",
    "SUCCESS": {
      "HEADING": "Kiitos osallistumisesta!",
      "INFO": "Kiitoksena saat %points %pointsLabel käytettäväksi myymälässämme.",
      "BONUS": "%points %pointsLabel on lisätty prosenttipisteitä, saldon päivitys näkyy seuraavan kerran kirjautuessasi sisään.",
      "BACK": "Aloitussivulle",
      "ALREADY_ANSWERED": "Olet jo vastannut tähän kyselyyn.",
      "COULD_NOT_SAVE": "Vastauksiasi ei voitu tallentaa. Varmista, että kaikki kysymykset on täytetty, tai ota yhteyttä tukeen.",
      "QUESTIONNAIRE_CLOSED": "Tämän kyselyn aika on päättynyt. Et voi enää vastata siihen.",
    },
    "YOUR_ANSWER": "Vastauksesi: ",
    "HISTORY_BACK": "Tee testi uudelleen",
  },
  "QUESTIONNAIRE": {
    "EMPTY_LIST": "Tällä hetkellä tässä kategoriassa ei ole kyselyjä",
  },
  "FILTER": {
    "OFFCANVAS": {
      "TITLE": "Suodatin",
      "SHOW_RESULT": "Näytä tulos",
      "SHOW_RESULT_TOTAL_MULTIPLE": "Näytä %total tulosta",
      "SHOW_RESULT_TOTAL_SINGLE": "Näytä %total tulos",
      "CLOSE_MENU": "Sulje suodatin-valikko",
    },
    "ACTIVE_FILTERS": {
      "CLEAR_ALL": "Tyhjennä kaikki suodattimet",
      "NO_ACTIVE_FILTERS": "Ei aktivoituja suodattimia",
      "PRICE_RANGE": "Hinta (%range)",
      "WITHIN_BALANCE": "Tuotteet pistesaldossa (%range)",
      "TOTAL_COUNT": "Näyttää %count tulosta",
      "COUNT": "Aktiiviset suodattimet",
    },
    "FILTERMENU": {
      "FILTER_BY_PRICE": "Suodata hinnan mukaan",
      "FILTER_BY_POINTS": "Suodata %pointsLabel mukaan",
      "RESET_PRICE": "Tyhjennä",
      "PRICE_FROM": "Alin pistemäärä",
      "PRICE_TO": "Ylin pistemäärä",
      "WITHIN_BALANCE": "Tuotteet pistesaldossa",
    },
    "FILTERBAR": {
      "ALL_FILTERS": "Kaikki suodattimet",
      "FILTER_BY_PRICE": "Suodata hinnan mukaan",
      "FILTER_BY_POINTS": "Suodata %pointsLabel mukaan",
      "PRICE_RANGE": "%min - %max %pointsLabel",
      "SORT": "Lajittele",
      "TOTAL_COUNT": "Näyttää %total tuotetta",
      "SORT_NAME_DOWN": "Ö-A",
      "SORT_NAME_UP": "A-Ö",
      "SORT_PRICE_DOWN": "Korkein hinta",
      "SORT_PRICE_UP": "Alhaisin hinta",
      "SORT_POSITION_DOWN": "Suosituimmat",
      "SORT_POPULAR_DOWN": "Suosituimmat",
      "SORT_VALUE_DOWN": "↓",
      "SORT_VALUE_UP": "↑",
      "SORT_RELEASE_DATE_DOWN": "Äskettäin lisätty",
      "SORT_LATEST_DOWN": " Äskettäin lisätty",
      "SORT_RELEVANCE_DOWN": "Merkityksellisyys",
    },
  },
  "TRANSACTIONS": {
    "TRANSACTION_ID": "Tapahtuma tunnus",
    "CLUB_OWNER": "Omistaja",
    "PRODUCT_ID": "Artikkelinumero",
    "QTY": "Määrä",
    "COST": "Summa",
    "MEMBER_ID": "Jäsennumero",
    "MEMBER_NAME": "Jäsenen nimi",
    "DATE": "Päivämäärä",
    "COMPANY": "Yritys",
    "EMAIL": "Sähköposti",
    "ADDRESS": "Osoite",
    "POSTCODE": "Postinumero",
    "CITY": "Kaupunki",
    "COUNTRY": "Maa",
    "TELEPHONE": "Puhelinnumero",
    "ALL_TRANSACTIONS": "Kaikki tapahtumat",
    "ONLY_INCOMING": "Vain luottokorttitapahtumat",
    "ONLY_OUTGOING": "Vain veloitustapahtumat",
    "SEARCH": "Hea tapahtumia",
    "ID": "ID",
    "TRANSACTION": "Tapahtuma",
    "POINTS": "%pointsLabel",
    "RECEIPT": "Kuitti",
    "RECEIPT_DETAILS": "Näytä kuitti",
    "POINTS_EXPIRES": "%pointsLabel vanhentuu",
    "REFUND": "Maksun palautus",
  },
  "LEADERBOARD": {
    "TITLE": "Tulostaulu",
    "SUBTITLE_PART1": "Täältä näet sijoituksesi konferenssimatkaamme vastaan. Onnea",
    "SUBTITLE_PART2": "Sijaintisi ja yhteenveto",
    "POSITION_OF_CONTESTANTS": "/ %amount %contestants",
    "DISTRIKT_DROPDOWN": "Valitse piiri: ",
    "MISSING_USER_DATA": "Käyttäjältäsi puuttuu pisteitä valitulle ajanjaksolle.",
    "MISSING_DATA": "Valitulle ajanjaksolle ei ole saatavilla tietoja.",

    "HERO": {
      "SHOW": "Näytä",
      "MONTH": "Kuukausi",
      "YEAR": "Vuosi",
    },
  },
  "CALCULATOR": {
    "POINTS": "%pointsLabel",
    "PLACEHOLDER_AMOUNT": "Rahoitettu summa (€)",
    "PLACEHOLDER_COUNT": "Sopimusten määrä...",
    "PLACEHOLDER_INTEREST": "Sopimustyyppi",
    "DROPDOWN_INTEREST": "Korollinen",
    "DROPDOWN_INTERESTFREE": "Koroton",
    "ERROR": "Täytä kaikki kentät",
  },
  "REPORTS": {
    "TITLE": "Raportit",
    "HISTORY_BUTTON": "Historia",
    "BACK": "takaisin",
    "EMPTY": "Näytettäviä raportteja ei ole",
    "ERROR": "Raporttien lataaminen epäonnistui.",
    "HISTORY": {
      "TITLE": "Historia",
      "SUBTITLE": "Tässä näet kaikki lähetetyt raportit.",
      "TRANSACTIONS": "Tapahtumahistoria",
    },
    "APPROVAL": {
      "TITLE": "Valmis merkintä",
      "SUBTITLE_PART1": "Tässä tyhjennät tilaukset",
      "SUBTITLE_PART2": "tyhjennä merkki",
      "SUBMIT": "Valmis",
    },
    "DOCUMENT": "Asiakirja",
  },
  "WISHLIST": {
    "TITLE": "Toivelista",
    "BACK": "Takaisin",
  },
  "NEWS": {
    "TITLE": "Uutuuksia",
    "LATEST": "Viimeisimmät uutiset",
    "SHOW_ALL": "Näytä kaikki uutiset",
    "NO_NEWS": "Ei uutuuksia näytettävissä.",
  },
  "OFFERS": {
    "LINK": "Siirry tarjouksiin",
    "EMPTY": "Ei tällä hetkellä voimassa olevia tarjouksia.",
  },
  "GALLERY": {
    "CLOSE": "Sulje kuvasto",
  },
  "IMAGE_UPLOADER": {
    "TITLE": "Kaikki valokuvat",
    "SHOW_ALL": "Katso lisää kuvia",
    "UPLOAD_IMAGE": "Lataa valokuva",
    "UPLOAD_IMAGE_BTN": "Lataa",
    "LATEST": "Viimeisimmät kuvat",
    "CHOOSE_FILE": "Valitse tiedosto",
    "EMPTY": "Ei näytettäviä kuvia",
  },
  "ADDRESS": {
    "FIRSTNAME": "Etunimi",
    "LASTNAME": "Sukunimi",
    "STREET_1": "Katuosoite 1",
    "STREET_2": "Katuosoite 2 (vapaaehtoinen)",
    "POSTCODE": "Postinumero",
    "CITY": "Kaupunki",
    "COUNTRY": "Maa",
    "EMAIL": "Sähköposti",
    "TELEPHONE": "Puhelinnumero",
    "COMPANY": "Yritys",
  },
  "VALUE": "Arvo",
  "TRY_AGAIN": "Yritä uudelleen",
  "CART": {
    "TITLE": "Ostoskorisi",
    "EMPTY": "Ostoskorisi on tyhjä",
    "START_SHOPPING": "Aloita ostokset",
    "CTA": "Siirry kassalle",
    "ALT": "Muuta ostoskorin sisältöä",
    "EDIT": "Muuta ostoskorin sisältöä",
    "TO_PAY": "Maksettava",
    "INCREMENT": "Lisää määrää",
    "DECREMENT": "Vähennä määrää",
    "QUANTITY": "Määrä",
    "OPEN_CART": "Avaa ostoskori",
    "CLOSE": "Sulje",
    "CLOSE_CART": "Sulje ostoskassi",
    "CONTINUE_SHOPPING": "Jatka ostoksia",
    "PRODUCTS": "Tuotteet",
    "SHIPPING": "Kuljetus",
    "SUBTOTAL": "Osasumma",
    "TOTAL": "Kokonaissumma",
    "GRANDTOTAL": "Kokonaissumma",
    "YOUR_BALANCE": "%pointsLabel-saldo",
    "SUMMARY": "Yhteenveto",
    "TO_CHECKOUT": "Siirry kassalle",
    "MOVE_TO_WISHLIST": "Siirrä toivelistalle",
    "REMOVE_PRODUCT": "Poista tuote",
    "INSUFFICIENT_FUNDS_HEADER_POSITIVE": "Tilauksen kokonaissumma alle kynnyksen",
    "INSUFFICIENT_FUNDS_HEADER_NEGATIVE": "Tilauksen kokonaissuma ylitää kynnyksen",
    "INSUFFICIENT_FUNDS_BODY": "Vähintään %spendingLimit% ostoksista on maksettava %pointsLabel. Jos ostoskorissasi on tuotteita jotka ylittävät tämän ehdon, sinun on poistettava tuotteita ostoskoristasi.",
    "INSUFFICIENT_FUNDS_POINTS_ONLY_BODY": "Ostoskorin arvo on korkeampi kuin %pointsLabel-saldosi. Yhtä tai useampaa tuotettasi ei voi maksaa erissä. Sinun on poistettava yksi tai useampi tuotteesi ostoskoristasi suorittaaksesi oston loppuun.",
    "INSUFFICIENT_FUNDS_BOTTOM_LABEL": "Sinulta puuttuu %points",
    "INSUFFICIENT_FUNDS_SPECIFIC_PARTNER": "Tuotteet ostoskorissasi voi ostaa vain pisteillä, jotka on ansaittu kumppaniltamme %partnerName.",
    "INSUFFICIENT_FUNDS_SUMMARY": "Kokonaisraja, joka on ylitetty, on",
  },
  "CHECKOUT": {
    "TITLE": "Kassalle",
    "NEED_HELP": "Tarvitsetko apua? Autamme sinua.",
    "CONTACT_CUSTOMER_SERVICE": "Ota yhteyttä asiakaspalveluun",
    "TO_PAYMENT": "Siirry maksuun",
    "CHOOSE_PAYMENT_METHOD": "Siirry edelleen maksuun",
    "POINTS_TITLE": "Maksa %pointsLabel",
    "POINTS_BALANCE": "Sinulla on %points. Valitse liukusäätimellä, kuinka paljon %pointsLabel haluat lunastaa. Loppusumma (entintään %currencyLimit% tilauksen kokonaismäärästä) voit maksaa luottokortilla.",
    "POINTS_BALANCE_JAVA": "Sinulla on %points. %javatext",
    "AMOUNT_TO_PAY": "Maksettava",
    "POINTS": "%pointsLabel",
    "PAY_WITH_CARD": "Maksa kortilla",
    "EDIT": "Muuta",
    "PAYMENT_AND_DELIVERY": "Maksu ja toimitus",
    "DISTRIBUTION": "Maksun jakotapa",
    "SENT_TO": "Lähetetään osoitteeseen",
    "ADDRESSES_TITLE": "Osoite",
    "EDIT_ADDRESS": "Muuta osoite",
    "ADDRESS_MINIMIZE": "Minimoi",
    "CONFIRM_ORDER": "Suorita tilaus loppuun",
    "CONTINUE_SHOPPING": "Jatka ostoksia",
    "PAYMENT_TITLE": "Maksu",
    "CARD_PAYMENT": "Korttimaksu",
    "I_ACCEPT_THE": "Hyväksyn",
    "TERMS": "Tilausehdot",
    "A_TERMS": "Käyttöehdot",
    "EX_TAX": "Ilman ALV",
    "INC_TAX": "Sisältää VAT",
    "EX_TAX_FULL": " Ilman ALV ",
    "INC_TAX_FULL": " Sisältää VAT ",
    "SUBTOTAL": "Osasumma",
    "PAYMENT_DISTRIBUTION": "Osamaksu",
    "AND": "ja",
    "OR": "tai",
    "MODE_OF_DELIVERY": "Toimitustapa",
    "VIRTUAL_DELIVERY": "Tilauksesi toimitetaan digitaalisesti.",
    "ADDRESS": {
      "ADDRESS": "Osoite",
      "SHIPPING": "Toimitusosoite",
      "BILLING": "Laskutusosoite",
      "SHIP_TO_SAME_ADDRESS": "Käytä sama laskutusosoitetta",
      "EMPTY_ADDRESS": "Osoite ei ole täydellinen, päivitä se",
      "CONTACT_INFO": "Yhteystiedot",
    },
    "STEPS": {
      "CART": {
        "SHORT": "Ostoskori",
        "LONG": "Ostoskori",
      },
      "SHIPPING": {
        "SHORT": "Toimitus",
        "LONG": "Vahvista osoitteesi",
      },
      "PAYMENT": {
        "SHORT": " Osamaksu ",
        "LONG": "Maksa",
      },
      "OVERVIEW": {
        "SHORT": "Yhteenveto",
        "LONG": "Yhteenveto",
      },
    },
  },
  "PAYMENT": {
    "TITLE": "Miten haluat maksaa?",
    "USE_PAY": "Maksa nyt",
    "USE_CARD": "Maksa kortilla",
    "USE_SAVED_CARD": "Maksa tallennetulla kortilla",
  },
  "PAYMENT_METHODS": {
    "APPLE_PAY": "pay",
  },
  "SUCCESS": {
    "TITLE": "Tilaus on rekisteröity",
    "SUB_TITLE": "Tilausnumero %number",
    "QTY": "Määrä: %qty",
    "STEPS": {
      "TITLE": "Kiitos tilauksestasi!",
      "HEADING": "Tilaus vahvistettu",
      "WHAT_NOW": "Mitä seuraavaksi?",
      "LABEL": "Tilauksesi",
      "ORDER_ID": "Tilausnumero %id",
      "QUESTIONS": {
        "HEADING": "Onko sinulla kysyttävää tilauksestasi?",
        "LABEL": "Siirry asiakaspalveluun",
        "URL": "/faq",
      },
      "CONFIRMATION": {
        "1": {
          "HEADING": "Vahvistus",
          "TEXT": "Kiitos tilauksestasi! Tilauksen vahvistus lähetetään antamaasi sähköpostiosoitteeseen: %email lyhyesti. Jos et saa sähköpostia, tarkista onko se päätynyt roskapostikansioosi.",
        },
        "2": {
          "HEADING": "Toimitus",
          "TEXT": "Tilauksesi toimitus tapahtuu joko omasta varastostamme tai yhdeltä kumppaneistamme. Tämä tarkoittaa, että tilaus voidaan toimittaa useassa eri lähetyksessä. Omasta varastostamme toimitettavat tilaukset lähetetään, kun kaikki tuotteet ovat varastossa. Omasta varastostamme tilatut tuotteet toimitetaan normaalisti 3-7 arkipäivän kuluessa. Kumppaneiltamme toimitettavat tilaukset lähetetään suoraan heidän varastostaan. Kumppaneiltamme tilatut tuotteet toimitetaan normaalisti 1-2 viikon kuluessa.<br/><br/>Tilauksen vahvistuksessa, joka lähetetään sähköpostiisi pian, näet, miten tilauksesi toimitetaan.<br/><br/>Digitaaliset tuotteet lähetetään suoraan antamaasi sähköpostiosoitteeseen tilauksessa.",
        },
      },
    },
  },
  "OLD_BROWSER": {
    "INFO": "Hei! Käytät vanhentunutta selainta. <a href=\"http://outdatedbrowser.com\" rel=\"noopener\" target=\"blank\">Päivitä selaimesi</a>, jotta voisit käyttää sivustoa .",
  },
  "NO_JAVASCRIPT": {
    "LOGIN": "Sinun on otettava Javascript käyttöön kirjautuaksesi sisään.",
  },
  "CATEGORY": {
    "TOTAL_COUNT": "(Näytä %total tuotetta)",
    "EMPTY": "Juuri nyt ei ole tuotteita näytettäväksi",
    "LOAD_MORE": "Lataa lisää",
    "PRODUCTS_IN_CATEGORY_TITLE": "Näyttää %itemsCount tuotetta",
    "PRODUCTS_IN_CATEGORY_TITLE_SINGULAR": "Näyttää 1 tuotteen",
    "PRODUCTS_IN_CATEGORY_TITLE_PLURAL": "Näyttää %itemsCount tuotetta",
    "PRODUCTS_IN_CATEGORY_TITLE_EMPTY": "Näyttää tuotteita",
  },
  "404": {
    "TITLE": "Etsimääsi sivua ei valitettavasti löydy",
    "BACK": "Haluaisitko palata",
    "BACK_LINK": "kotisivu",
  },
  "SERVER_ERROR": {
    "TITLE": "Palvelinvirhe",
    "TEXT": "<strong>Tarvitsetko apua?</strong><br/> Ota yhteys <a href=\"mailto:info@awardit.com\">info@awardit.com</a>",
  },
  "ADMINISTRATION": {
    "APPROVE_MEMBERS": "Hyväksy jäsenet",
    "TITLE": "Hallinto",
  },
  "SEARCH": {
    "AND_ACTIVE_FILTERS": "ja aktiiviset suodattimet",
    "TITLE_PREFIX": "Hae: ",
    "PLACEHOLDER": "Mitä etsit?",
    "ERROR_MINIMUM_CHARACTERS": "Käytä vähintään %characters merkkiä kyselyssä",
    "EMPTY_SEARCH_RESULT": "Vastaavia tuotteita ei löytynyt",
    "PRODUCT_IN_SEARCH_RESULT": "Löytyi 1 yhteensopiva tuote",
    "PRODUCTS_IN_SEARCH_RESULT": "Löytyi %itemsCount yhteensopivaa tuotetta",
    "SEARCHING_FOR": "Hakutulos",
    "TYPE": {
      "CATEGORY": "Kategoria",
      "BRAND": "Merkki",
    },
  },
  "HEADER": {
    "CLOSE_MENU": "Sulje valikko",
    "SEARCH": "Hae",
    "CATEGORIES": "Kategoriat",
    "CLOSE_CATEGORIES": "Sulje kategoriat",
    "AFFILIATE_CATEGORIES": "Kumppanit",
    "BRANDS": "Tuotteet",
    "EARN": "Kerää %pointsLabel",
    "REDEEM": "Lunasta %pointsLabel",
    "CLOSE_BRANDS": "Sulje tuotteet",
    "WISHLIST": "Toivelista",
    "ACCOUNT": "Tililleni",
    "CHECKOUT": "Kassalle",
    "LOGOUT": "Kirjaudu ulos",
    "DELIVER_TO": "Toimitetaan osoitteeseen",
    "HOW_DOES_IT_WORK": "Kuinka se toimii?",
    "MY_ACCOUNT": "Tililleni",
    "CUSTOMER_SERVICE": "Asiakaspalvelu",
    "OUR_OFFER": "Meidän tarjoukset",
    "NEWS": "Uutuudet",
    "CATEGORY_SEE_ALL": "Näytä kaikki %categoryName",
    "QUESTIONNAIRES": "Kyselylomakkeet",
    "OFFER_LIST": "Tarjoukset",
    "LEADS": "Johtoja",
    "FAQ": "Usein kysyttyä",
  },
  "FOOTER": {
    "CATEGORIES": "Kategoriat",
    "LINKS": {
      "CONTACT": "Ottaa yhteyttä",
      "TITLE": "Linkit",
      "START": "Koti",
      "CATEGORIES": "Kategoriat",
      "BRANDS": "Tuotteet",
      "SEARCH": "Etsi valikoimasta",
      "WISHLIST": "Toivelista",
      "MY_ACCOUNT": "Tililleni",
      "GO_TO_MY_ACCOUNT": "Tililleni",
      "ALL_TRANSACTIONS": "Näytä kaikki tapahtumat",
    },
    "NEED_HELP": {
      "TITLE": "Tarvitsetko apua? Autamme sinua.",
      "CONTACT": "Ota yhteyttä",
      "DATE": "Yleiset vapaapäivät 10-12",
      "COOKIES": "Hallinnoi evästeitä",
    },
    "LEGAL": {
      "PRIVACY_POLICY": "Tietosuojakäytäntö",
      "TERMS": "Tilausehdot",
      "FAQ": "Usein kysyttyä",
      "PRIVACY_NOTICE": "Privacy Notice",
    },
  },
  "BREADCRUMBS": {
    "HOME": "Aloitussivu",
    "SEARCH": "Hakutulos \"%query\"",
  },
  "PRODUCT": {
    "ADD_TO_CART": "Lisää ostoskoriin",
    "DESCRIPTION": "Tuotekuvaus",
    "OUT_OF_STOCK": "Loppu varastosta",
    "OTHERS_ALSO_LIKED": "Muita sinua kiinnostavia tuotteita",
    "OPTION_DROPDOWN_PLACEHOLDER": "Valitse vaihtoehto",
    "LOGIN_TO_PURCHASE": "Kirjaudu sisään ja tilaa",
    "CHANGE_IMAGE": "Vaihda kuva",
    "INSUFFICIENT_FUNDS_HEADING": "Osamaksu",
    "INSUFFICIENT_FUNDS_BODY": "Jos sinulla ei ole riittävästi %pointsLabel tämän tuotteen ostamiseen, voit maksaa loput (enintään %currencyLimit% kokonaissummasta) maksukortilla.",
    "PERQUISITE_VALUE": "%value (vaadittava arvo ilman ALV verotuksen laskemiseksi)",
    "RESTRICTION_PRODUCT_ADD_TO_CART": "Tätä tuotetta voi ostaa vain ilman muita tuotteita ostoskorissa. Haluatko poistaa korista nykyiset tuotteet ja jatkaa?",
    "RESTRICTION_PRODUCT_IN_CART": "Tuotetta, jonka lisäät ostoskoriin, ei voida yhdistää olemassa oleviin tuotteisiin. Haluatko poistaa tuotteet ostoskorista ja lisätä tämän?",
    "PRODUCTS_CANNOT_BE_COMBINED": "Tuotteita ei voi yhdistää",
    "SPECIFIC_POINT_DEFICIT_HEADING": "Sinulla ei ole tarpeeksi pisteitä tämän tuotteen ostamiseen",
    "SPECIFIC_POINT_DEFICIT_BODY": "Tämä tuote voidaan ostaa vain pisteillä, jotka on ansaittu kumppaniltamme %partner, ja sinulta puuttuu tällä hetkellä %points.",
    "ESTIMATED_DELIVERY": {
      "NORMAL": "Toimitus 3-7 työpäivän kuluessa",
      "VIRTUAL": "Lähetetään suoraan sahköpostitse",
    },
  },
  "STRIPE": {
    "CARD_NUMBER": "Korttinumero",
    "MM/YY": "MM/YY",
    "CVC": "CVC",
    "PROCESSING": "Processing",
  },
  "LEADS": {
    "CRITERIA": {
      "HEADER": "Kriteeri",
      "LIST": {
        "FIRST": "Sinulle tarjotaan mahdollisuus lähettää jopa 10 liidiä vuodessa",
      },
    },
    "POINTS": {
      "HEADER": "%pointsLabel liidejä varten",
      "SUBHEADER": "Liidi voi tuottaa %pointsLabel kolmella eri tasolla:",
      "LIST": {
        "FIRST": "Hyväksytty etumatka - 5 000 %pointsLabel",
        "SECOND": "Vihje luo uuden kumppanin - 20 000 %pointsLabel",
        "THIRD": "Vihje (nyt kumppani) aloittaa 2 sopimusta - toiset 20 000 %pointsLabel",
      },
    },
    "LEVEL_ONE": {
      "HEADER": "Taso 1 - 5 000 %pointsLabel",
      "SUBHEADER": "Liidin on täytettävä seuraavat kriteerit, jotta se voidaan hyväksyä:",
      "LIST": {
        "FIRST": "Yritys myy rahoitettavia laitteita",
        "SECOND": "Yritys toimii vain B2B-sektorilla",
        "THIRD": "Yritys ei ole tällä hetkellä yhteydessä Release Finans -yritykseen tai on sen olemassa oleva kumppani",
        "FOURTH": "Yritys ei myy mitään seuraavista: rakennuskoneita tai ajoneuvoja",
        "FIFTH": "Yhtiö on Release Finans hyväksymä luotto",
        "SIXTH": "Yritys ja yhteyshenkilö eivät ole aiemmin ilmoittautuneet liidiksi",
      },
      "FOOTER": "Hyväksytylle lähetetylle liidille myönnetään %pointsLabel, kun olemme tarkistaneet, että kärki täyttää yllä olevat kriteerit. Jos liidi hyväksytään, %pointsLabel näkyy saldonäkymässäsi portaalissa 15 työpäivän kuluessa vihjeen lähettämisestä.",
    },
    "LEVEL_TWO": {
      "HEADER": "Taso 2 - 20 000 %pointsLabel",
      "SUBHEADER": "Liidin on täytettävä seuraavat kriteerit, jotta se voidaan hyväksyä:",
      "LIST": {
        "FIRST": "Vihje synnyttää uuden yhteistyökumppanin",
      },
      "FOOTER": "%pointsLabel, jonka voit saada, jos liidisi on luonut yhteistyön, voi kestää jonkin aikaa. Tämä riippuu yksinkertaisesti siitä, kuinka kontakti tippuun saaneen osapuolen kanssa kehittyy. Yhteistyön määritelmä on, että yhteistyösopimus on olemassa. Jos yhteistyötä ei voida aloittaa 6 kuukauden kuluessa, sinulle ilmoitetaan tästä ja %pointsLabel ei tule näkyviin.",
    },
    "LEVEL_THREE": {
      "HEADER": "Taso 3 – ylimääräinen 20 000 %pointsLabel",
      "SUBHEADER": "Liidin on täytettävä seuraavat kriteerit, jotta se voidaan hyväksyä:",
      "LIST": {
        "FIRST": "Yhteistyökumppani aloittaa 2 sopimusta",
      },
      "FOOTER": "Näiden %pointsLabelin edellytyksenä on, että aikaisemmat tasot täyttyvät, ts. että yhteistyösopimus on tehty. Seuranta ja %pointsLabelin jakelu kahdelle alkaneelle sopimukselle tapahtuu kuukausittain.",
    },
  },
  "VALIDATION": {
    "REQUIRED": "Tämä kenttä on pakollinen.",
    "EMAIL": "Kirjoita ajankohtainen s-postiosoite.",
    "PHONE": "Kirjoita voimassaoleva puhelinnumero.",
    "NUMERIC": "Kentässä saa olla vain numeroita.",
    "LENGTH_LT": "Kentässä on liian monta merkkiä.",
    "POSTCODE": "Kirjoita ajankohtainen postinumero.",
    "MATCH": "Salasana on väärin",
    "NUMBER_GT": "Arvon on oltava suurempi",
    "NUMBER_LT": "Arvon on oltava pieniempi",
    "DATE_INVALID_FORMAT": "Väärä muoto (VVVV-KK-PP)",
    "DATE_INVALID_DATE": "Väärä päivämäärä",
    "NOT_A_VALID_GENDER": "Valitse sukupuoli",
    "PASSWORD_VALIDATE": "Vähintään 6 merkkiä",
  },
  "MESSAGE": {
    "ERROR": "Virhe",
    "SUCCESS": "Onnistuit",
    "INSUFFICIENT_FUNDS": {
      "HEADING": " Kokonaissummasi ylittää kynnysarvon",
      "BODY": " Vähintään %spendingLimit% ostoksista on maksettava %pointsLabel. Jos ostoskorissasi on tuotteita jotka ylittävät tämän ehdon, sinun on poistettava tuotteita ostoskoristasi.",
      "POINTS_ONLY_BODY": "Ostoskorin arvo on korkeampi kuin %pointsLabel-saldosi. Yhtä tai useampaa tuotettasi ei voi maksaa erissä. Sinun on poistettava yksi tai useampi tuotteesi ostoskoristasi suorittaaksesi oston loppuun.",
      "BUTTON_LEFT": "Hylkää",
      "BUTTON_RIGHT": "Muokkaa ostoskorin sisältöö",
    },
    "MISSING_DETAILS": {
      "HEADING": "Toimenpiteitä tarvitaan",
      "NUMBER_NOT_FOUND": "Profiilissasi ei ole puhelinnumeroa. Täytä tietosi.",
      "DOB_NOT_FOUND": "Profiilissasi ei ole syntymäpäivää. Täytä tietosi.",
      "FIRSTNAME_NOT_FOUND": "Profiilissasi ei ole etunimeä. Täytä tietosi.",
      "LASTNAME_NOT_FOUND": "Profiilissasi ei ole sukunimeä. Täytä tietosi.",
      "EMAIL_NOT_FOUND": "Profiilissasi ei ole sähköpostiosoitetta. Täytä tietosi.",
      "GENDER_NOT_FOUND": "Profiilissasi ei ole sukupuolta. Täytä tietosi.",
      "ORGNR_NOT_FOUND": "Profiilissasi ei ole organisaationumeroa. Täytä tietosi.",
      "BUTTON_LEFT": "Hallinnoi myöhemmin",
      "BUTTON_RIGHT": "Avaa tilini",
    },
    "BLOCKED": "Et voi tällä hetkellä tehdä ostoksia kaupassa, kysymyksissä ota yhteyttä yhteyshenkilöösi.",
  },
  "STATUS_CODE": {
    /* Client */
    "ERROR_NOT_LOGGED_IN": "Et näytä olevan kirjautunut sisään. Lataa sivu uudelleen ja yritä uudelleen.",
    "FALLBACK": "Tuntematon virhe. Lataa sivu uudelleen ja kokeile uudestaan.",
    "UNKNOWN": "Tuntematon virhe. Lataa sivu uudelleen ja kokeile uudestaan.",
    "NETWORK_ERROR": "Kutsu keskeytyi. Tarkista, että sinulla on toimiva internet-liittymä ja kokeile uudestaan hetken kuluttua.",
    "PLACE_ORDER_CATCH": "Tapahtui virhe. Kokeile myöhemmin uudelleen.",
    "CHANGE_PASSWORD_ERROR": "Oddottamaton virke tapahtui, yritä uudelleen myöhemmin.",
    "CHANGE_PASSWORD_SUCCESS": "Salasana päivitetty.",
    "SEND_ORDER_SUCCESS": "Raporttisi on lähetetty.",
    "SEND_ORDER_ERROR": "Tapahtui virhe. Yritä myöhemmin uudelleen.",
    "MAIL_SENT": "Sinulle on lähetetty sähköpostia.",
    "PASSWORD_UPDATE": "Salasanan päivittäminen onnistui.",
    "TRANSFER_POINTS_ERROR": "Pisteiden siirto epäonnistui.",
    "TRANSFER_POINTS_SUCCESS": "Pisteiden siirto onnistui.",
    "ADD_PHONE_NUMBER": "Anna matkapuhelinnumero.",
    "POST_FORM_SUCCESS": "Vastauksesi on lähetetty.",
    "POST_FORM_ERROR": "Vastaustasi ei voitu lähettää, tarkista, että kaikki vaaditut tiedot on annettu.",

    "REMOVE_MEMBER_ERROR": "Jäsenen poistaminen epäonnistui.",
    "REMOVE_MEMBER_SUCCESS": "Jäsen on poistettu.",
    "ADD_MEMBER_ERROR": "Jäsenen lisääminen epäonnistui.",
    "ADD_MEMBER_SUCCESS": "Jäsen on lisätty.",
    "UPDATE_MEMBER_ERROR": "Jäsenen päivittäminen epäonnistui.",
    "UPDATE_MEMBER_SUCCESS": "Jäsen on päivitetty.",
    "GET_TRANSFERABLEMEMBERS_ERROR": "Jäseniä ei voitu hakea, yritä uudelleen.",
    "TRANSFER_MEMBER_POINTS_ERROR": "Pisteitä ei voitu siirtää, yritä uudelleen.",
    "TRANSFER_MEMBER_POINTS_SUCCESS": "Pisteet on siirretty.",

    "errorProductQuantityNotAvailable": "Ilmoittamaasi määrää ei juuri nyt ole saatavilla.",
    "errorProductNotInStock": "Tuotetta, jota yritit laittaa ostoskoriin, ei valitettavasti juuri nyt ole varastossa.",
    "errorProductMaxQuantity": "Ilmoittamaasi määrää ei ole juuri nytt saatavilla.",
    "errorProductNotChosenVariant": "Valitse %attributeLabel, jotta voisit laittaa tuotteen ostoskoriisi.",
    "termsNotAccepted": "Sinun täytyy hyväksyä tilausehdot voidaksesi tehdä tilauksen.",

    "errorInvalidLogin": "Virheelliset sisäänkirjautumistiedot.",
    "errorLoginFailed": "Virheelliset sisäänkirjautumistiedot.",
    "errorMissingCustomer": "Asiakasta, jolla yrität kirjautua sisään, ei ole olemassa",
    "errorInsufficientawarditPointsForPurchase": "Tilin %pointsLabel-saldo on liian alhainen",
    "errorBlacklistedEmail": "Ilmoittamasi s-postiosoite on jo käytössä. Ilmoita toinen osoite.",
    "errorInvalidEmailOrPassword": "Virheelliset sisäänkirjautumistiedot.",
    "errorResetPassword": "Salasana ei voitu päivittää.",
    "updateCustomerEmail_errorNotLoggedIn": "Et ole kirjautunut sisään. Kirjaudu sisään uudelleen.",
    "updateCustomerEmail_errorInvalidEmail": "Sähköpostiosoite ei ole väärä, tarkista se.",
    "updateCustomerEmail_errorEmailExists": "Ilmoittamasi s-postiosoite on jo käytössä. Ilmoita toinen osoite.",
    "QUIZ_ERROR_ANSWERED": "Olet jo vastannut tähän tietokilpailuun",
    "SEND_QUIZ_SUCCESS": "Vastauksesi on lähetetty",
    "SEND_IMAGE_ERROR": "Valokuvaasi ei voitu lähettää",
    "SEND_IMAGE_SUCCESS": "Kuvasi on lähetetty",
    "TEAMPOT_ERROR": "Tapahtui virhe, %pointsLabel ei lähetetty",
    "TEAMPOT_SUCCESS": "%pointsLabel on lähetetty",
    "TEAMPOT_OPTION_ERROR": "Toimintapalkki ei voi olla tyhjä.",
    "TEAMPOT_POINTS_ERROR": "%pointsLabel-ekenttä ei voi olla tyhjä.",
    "TEAMPOT_RANK_ERROR": "Luokkauskenttä ei voi olla tyhjä.",
    "TEAMPOT_USERS": "Jäsenet",

    "CART_NO_ITEMS": "Ostoskori on tällä hetkellä tyhjä",

    "AFFILIATE": {
      "ACTIVATED": "Tarjous aktivoitu",
      "NOT_ACTIVATED": "Torjousta ei voitu aktivoida.",
      "ERROR_RELOADING": "Päivitettyä tarjousta ei voitu ladata. Päivitä sivu nähdäksesi muutokset.",
    },

    "STRIPE_ERROR": {
      "YOUR_CARDS_EXPIRATION_DATE_IS_INCOMPLETE": "Maksukortin viimeinen voimassaolopäivä puuttuu tiedosta.",
    },

    "TINK_CALLBACK": {
      "1": "You've now successfully registered your conscent and you're now eligable to receive %pointsLabel from all your purchases.", // Complete
      "2": "Suostumstasi ei voitu rekisteröidä .",
      "3": "Emme saaneet yhteyttä pankiisi.",
      "4": "Maksusuorituksessasi tapahtui virhe.",
      "5": "Noudettaessa tietojasi tapahtui virhe.",
      "6": "Tink-linkissä on jotain vikaa. Ota yhteyttä tukeen.",
      "7": "Olet peruuttanut todennuksen.",
      "8": "Tunnistautuminen epäonnistui.",
      "9": "Sisäinen virhe Tinkissä. Ota yhteyttä tukeen.",
      "10": "Tilapäinen virhe Tinkissä. Yritä myöhemmin uudelleen ja ota yhteyttä asiakaspalveluun, jos virhe ei poistu.",
      "UNKNOWN": " Noudettaessa tietojasi tapahtui virhe.",
      "GENERIC_ERROR": " Noudettaessa tietojasi tapahtui virhe.",
    },

    "REGISTER_MEMBER": {
      "SUCCESS_ACTIVATE": "Tilisi on nyt aktivoitu!",
      "SUCCESS_REG": "Käyttäjätietosi on nyt rekisteröity, tarkista sähköpostisi aktivoidaksesi tilisi.",
    },

    "RECOMMEND_MEMBER": {
      "ERROR": "Suositusta ei voitu lähettää käyttäjälle.",
    },

    "ITEM_REMOVED_FROM_CART": "%name posetettu ostoskoristasi, koska stä ei ole enään saatavilla.",

    "MEMBERGROUP": {
      "MEMBER_UPDATED": "Ryhmän jäsen on nyt päivitetty.",
      "MEMBER_ADDED": "Ryhmän jäsen on nyt lisätty ryhmään.",
      "MEMBER_REMOVED": "Ryhmän jäsen on nyt poistettu ryhmästä.",
    },
  },

  "ADDITIONAL_MEMBER_NUMBERS": {
    "TABLE": {
      "NAME": "Nimi",
      "NUMBER": "Asiakasnumero",
    },
  },

  "MOTIVATION_POT": {
    "MOTIVATION_POT_TITLE": "Joukkueen potti",
    "SUB_TITLE": "Täällä voit jakaa %pointsLabel työntekijöille",
    "USERS_HEADER": "%pointsLabel-eiden jako",
    "CHOOSE_ALL": "Valitse kaikki",
    "POINTS_OF_CHOSEN": "%pointsLabel (x %userAmount valittu)",
    "POINTS": "%pointsLabel",
    "POINTS_BALANCE_ERROR": "Valitettavasti %pointsLabel-määräsi ei riitä",
    "SHARED_POINTS": "Hajautettu",
    "COMMENT": "Kommentti",
    "POINTS_AVAILABLE": "%pointsLabel saatavilla",
    "POINTS_HANDED_OUT": "%pointsLabel myönnetty",
    "POINTS_LEFT_IN_POT": "%pointsLabel pottiin jäljellä",
    "POINTS_HEADER": "%pointsLabel-potti",
    "PERIOD": "Kausi",
    "TOTAL_POINTS": "%pointsLabel-potti yhteensä",
    "POINTS_LEFT": "%pointsLabel jäljellä",
    "PREVIOUS_HANDED_OUT_HEADER": "Aiemmat osingot",
    "ACTIVITY": "Toiminta",
    "MEMBER_COUNT": "Jäsenten määrä",
    "RANK": "%pointsLabel",
    "RANK_OF_CHOSEN": "Sijoitus (x %userAmount valittu)",
    "DATE": "Päivämäärä",
    "RECEIVERS": "Vastaanotin",
    "CHOOSE_AVTIVITY": "Valitse toiminta…",
    "VALIDATION_DATE": "%fromDate to %toDate",
  },
  "VERIFY_EMAIL": {
    "MAIL_ADDRESS": "%email",
    "FIRST_STEP": {
      "TEXT": {
        "FIRST_PART": "Hei, ei hätää, meidän on vain varmistettava, että tämä on sähköpostiosoitteesi: ",
      },
    },
    "SECOND_STEP": {
      "HEADER_TEXT": {
        "FIRST_ROW": "Sinun olisi nyt pitänyt saada sähköposti osoitteeseen ",
        "SECOND_ROW": "Napsauta sähköpostissa olevaa linkkiä aktivoidaksesi tilisi uudelleen.",
      },
      "TEXT": {
        "NO_MAIL": {
          "HEADER": "Eikö sähköposti tule perille?",
          "TEXT": "Lähetä uudelleenaktivointisähköposti napsauttamalla ",
          "HERE": "tässä",
        },
      },
    },
  },
  "MEMBERGROUPVIEW": {
    "PARTICIPANT": "Osallistuja",
    "STATUS": "Tila",
    "GROUP_MEMBER": "Ryhmän jäsen",
    "POINTS": "%pointsLabel",
    "TRANSACTIONS": "Näytä tapahtumat",
    "REMOVE": "Poista",
    "UPDATE": "Päivittää",
    "POINTSOPTION_0": "Jäsen voi tarkastella ja lunastaa vain omia %pointsLabel",
    "POINTSOPTION_1": "Jäsen näkee vain ryhmän %pointsLabel, mutta voi lunastaa omat %pointsLabel, jos heillä on niitä",
    "POINTSOPTION_2": "Jäsen voi tarkastella ja lunastaa kaikkia %pointsLabel",
    "YES": "Joo",
    "NO": "Ei",
    "REMOVE_TITLE": "Poista ryhmän jäsen",
    "REMOVE_CONFIRM": "Haluatko todella poistaa ryhmän jäseniä \"%member\"?",
    "ADD_MEMBER": "Lisää uusi jäsen",
    "ORGNR": "Organisaation numero",
    "LINK": "Hallinnoi jäsenryhmää",
  },
};
